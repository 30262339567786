<template>
  <a-row>
    <a-col :xl="10" :md="16" :xs="24">
      <div class="user-info-form">
        <BasicFormWrapper>
          <a-form style="width: 100%" name="info" :model="formState" :rules="rules" ref="formRef" @finish="onSubmit"
            :layout="formState.layout">
            <sdHeading class="form-title" as="h4">
              Personal Information
            </sdHeading>

            <a-form-item label="Class">
                <a-checkbox v-model:checked="formState.isOperation" name="operations">Operations</a-checkbox>
                <a-checkbox v-model:checked="formState.isSupport" name="support">Support</a-checkbox> 
            </a-form-item>
            <a-form-item ref="name" label="Full name" name="name">
              <a-input v-model:value="formState.name" placeholder="Full name" />
            </a-form-item>
            <a-form-item label="HS code" name="hscodeId">
              <a-select mode="multiple" v-model:value="formState.hsCodesIDs" placeholder="HS Code" :options="hsCodeData"
                :not-found-content="hsCodeFetching ? undefined : null" :filter-option="false" @search="searchHSCode">
                <template v-if="hsCodeFetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
            <a-form-item label="Activity" name="activitiesID">
              <a-select mode="multiple" v-model:value="formState.activitiesID" placeholder="Activities">
                <a-select-option v-for="(activity, index) in ActivityApi" :key="index" :value="activity.id">
                  {{ activity.name }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item v-if="hasProductLine" label="Line Product" name="productLineIDs">
              <a-select mode="multiple" v-model:value="formState.productLineIDs" placeholder="Line Product">
                <a-select-option v-for="(productLine, index) in ProductLinesApi" :key="index" :value="productLine.id">
                  {{ productLine.name }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item name="email" label="Email">
              <a-input v-model:value="formState.email" :style="{ width: '100%' }" />
            </a-form-item>

            <a-form-item name="phoneNumber" label="Contact number ">
              <a-input v-model:value="formState.phoneNumber" :style="{ width: '100%' }" />
            </a-form-item>

            <a-form-item name="taxRegistration" label="Tax Registration">
              <a-input v-model:value="formState.taxRegistration" :style="{ width: '100%' }" />
            </a-form-item>

            <a-form-item name="numeroRegistreCommerce" label="Commerce Registration">
              <a-input v-model:value="formState.numeroRegistreCommerce" :style="{ width: '100%' }" />
            </a-form-item>
            <a-form-item label="Country" name="countryId">
              <a-select v-model:value="formState.countryId" placeholder="country">
                <a-select-option v-for="(country, index) in CountryApi" :key="index" :value="country.id">
                  {{ country.name }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="city" label="City">
              <a-input v-model:value="formState.city" :style="{ width: '100%' }" />
            </a-form-item>
            <a-form-item name="postalCode" label="Postal Code">
              <a-input v-model:value="formState.postalCode" :style="{ width: '100%' }" />
            </a-form-item>
            <a-form-item label="Adress">
              <a-textarea v-model:value="formState.address" :style="{ width: '100%' }" placeholder="Adresse ..."
                name="address" class="sDash_unresizable" />
            </a-form-item>

            <sdButton htmlType="submit" type="primary" class="ant-btn">
              Edit
            </sdButton>
          </a-form>
        </BasicFormWrapper>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { BasicFormWrapper } from "../../styled";
import {
  computed,
  onMounted,
  reactive,
  ref,
  watch,
  toRefs,
  defineComponent,
} from "vue";
import { useStore } from "vuex";
import { DataService } from "@/config/dataService/dataService";
import { debounce } from "lodash-es";

const GeneralInfo = defineComponent({
  name: "GeneralInfo",
  components: { BasicFormWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const partnerApi = computed(() => state.partnerApi.data);
    const ActivityApi = computed(() => state.ActivityApi.data);
    const ProductLinesApi = computed(() => state.ProductLineApi.data);
    const CountryApi = computed(() => state.CountryApi.data);

    const values = ref("");
    const formRef = ref();

    var formState = reactive({
      layout: "vertical",
      id: 0,
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      countryId: "",
      city: "",
      postalCode: "",
      isOperation: false,
      isSupport: false,
      activitiesID: [],
      hsCodesIDs: [],
      productLineIDs: [],
      editionMode: false,
    });

    onMounted(() => {
      dispatch("activityInfo");
      dispatch("productLineInfo");
      dispatch("countryInfo");

      if (partnerApi.value && partnerApi.value.data) {
        let partner = partnerApi.value.data;
        formState.editionMode = true;
        formState.id = partner.id;
        getPartnerHsCode(partner.id);
        formState.name = partner.name;
        formState.email = partner.email;
        formState.phoneNumber = partner.phoneNumber;
        formState.address = partner.address;
        formState.taxRegistration = partner.taxRegistration;
        formState.numeroRegistreCommerce = partner.numeroRegistreCommerce;
        formState.countryId = partner.countryId;
        formState.isOperation = partner.isOperation;
        formState.isSupport = partner.isSupport;
        formState.city = partner.city;
        formState.postalCode = partner.postalCode;
        formState.activitiesID = [];
        if (partner.activitiesID && partner.activitiesID.length) {
          formState.activitiesID = partner.activitiesID;
        } else if (partner.activities) {
          formState.activitiesID = partner.activities.map((a) => a.id);
        }
        if (partner.hsCodesIDs && partner.hsCodesIDs.length) {
          formState.hsCodesIDs = partner.hsCodesIDs;
        } else if (partner.hsCodes) {
          formState.hsCodesIDs = partner.hsCodes.map((a) => a.id);
        }
        formState.productLineIDs = [];
        if (partner.productLineIDs && partner.productLineIDs.length) {
          formState.productLineIDs = partner.productLineIDs;
        } else if (partner.productLines) {
          formState.productLineIDs = partner.productLines.map((a) => a.id);
        }
      }
    });
    const rules = {
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Mail is required",
          trigger: "change",
        },
      ],
      activitiesID: [
        {
          type: "array",
          required: true,
          message: "Select activities",
          trigger: "change",
        },
      ],
      productLineIDs: [
        {
          type: "array",
          required: true,
          message: "Select a product line",
          trigger: "change",
        },
      ],
    };
    const hasProductLine = computed(() => {
      let count = 0;
      if (formState.activitiesID.length) {
        formState.activitiesID.forEach((activityId) => {
          const selectedActivities = ActivityApi.value.filter((activity) =>
            formState.activitiesID.includes(activity.id)
          );
          const filteredData = selectedActivities.filter(
            (activity) => activity.id === activityId && activity.hasProductLine
          );
          if (filteredData.length > 0) {
            count++;
          }
        });
      }
      return count;
    });
    const handleFinish = (values) => {
      values.value = values;
    };

    const handleFinishFailed = (errors) => {
      console.log(errors);
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          dispatch("partnerUpdateData", {
            formState,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    ///// HS CODE
    const hsCodeSearchState = reactive({
      hsCodeData: [],
      hsCodeValue: [],
      hsCodeFetching: false,
    });
    const getPartnerHsCode = async (hsCodeId) => {
      const query = await DataService.get(
        `api/HSCode/GetByPartenaireId?PartenaireId=${hsCodeId}`
      );
      if (query.succeeded) {
        const data = query.data.map((element) => ({
          label: element.hsCodeName,
          value: element.hsCodeId,
        }));
        hsCodeSearchState.hsCodeData = data;
      }
    };
    let lasthsCodeFetchId = 0;

    const searchHSCode = debounce(async (hscode) => {
      lasthsCodeFetchId += 1;
      const fetchId = lasthsCodeFetchId;
      hsCodeSearchState.hsCodeData = [];
      hsCodeSearchState.hsCodeFetching = true;
      DataService.get(
        `api/HSCode?OrderBy=name&HSCodeName=${hscode}&PageSize=10`
      )
        .then((response) => response.data)
        .then((list) => {
          if (fetchId !== lasthsCodeFetchId) {
            return;
          }
          const data = list.map((element) => ({
            label: element.name,
            value: element.id,
          }));
          hsCodeSearchState.hsCodeData = data;
          hsCodeSearchState.hsCodeFetching = false;
        });
    }, 300);
    watch(formState.hsCodesIDs, () => {
      hsCodeSearchState.data = [];
      hsCodeSearchState.hsCodeFetching = false;
    });
    return {
      ...toRefs(hsCodeSearchState),
      ActivityApi,
      values,
      formState,
      handleFinish,
      handleFinishFailed,
      partnerApi,
      rules,
      onSubmit,
      formRef,
      ProductLinesApi,
      CountryApi,
      searchHSCode,
      hasProductLine,
    };
  },
});

export default GeneralInfo;
</script>
